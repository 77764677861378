import React, { Suspense } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { LayoutSplashScreen } from "./MetronicSplashScreen";
import { ResponsiveNavigation } from "./app/components/ResponsiveNavigation";
import SideDrawer from "./app/components/SideDrawer";
import Backdrop from "./app/components/Backdrop";
import Test from "./app/modules/test/test";
import sideDrawer from "./app/components/SideDrawer";
import "./fonts/HiraginoFont/hiragino.css";
import Footer from "./app/modules/footer/footer";
const navLinks = [
  {
    text: "Take Tests",
    path: "/startTest",
  },
  {
    text: "Demo Tests",
    path: "/startDemoTest",
  },
  {
    text: "About IC Index",
    path: "/",
  },
  {
    text: "Blog",
    path: "/",
  },
  {
    text: "About IC",
    path: "/",
  },
];
class App extends React.Component {
  state = {
    sideDrawerOpen: false,
  };
  drawerToggleClickHandler = () => {
    console.log("hii");
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  render() {
    let sidedrawer;
    let backdrop;
    if (this.state.sideDrawerOpen) {
      sidedrawer = (
        <SideDrawer navLinks={navLinks} click={this.backDropClickHandler} />
      );
      backdrop = <Backdrop click={this.backDropClickHandler} />;
    }
    return (
      <Suspense style={{ height: "100%" }} fallback={<LayoutSplashScreen />}>
        <BrowserRouter>
          <ResponsiveNavigation
            navLinks={navLinks}
            logo={logo}
            drawerToggleClickHandler={this.drawerToggleClickHandler}
          />
          {sidedrawer}
          {backdrop}
          {/* <Test /> */}
          <Routes />
        </BrowserRouter>
      </Suspense>
    );
  }
}
export default App;
