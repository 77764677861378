import React from "react";

export default function QuestionPanel({
  ariaLabel,
  selected,
  onTabSelected,
  definition,
}) {
  console.log(definition);
  return (
    <div role="tablist" aria-label={ariaLabel} className="retab__tablist">
      {definition.map((tabDefinition, index) => (
        <Tab
          key={tabDefinition.uid}
          id={tabDefinition.htmlId}
          controls={tabDefinition.panelHtmlId}
          isSelected={index === selected}
          onSelected={() => onTabSelected(index)}
        >
          {" "}
          <>
            {tabDefinition.content}{" "}
            {tabDefinition.answerSelected ? (
              <span> - {tabDefinition.answerSelected}</span>
            ) : (
              ""
            )}
          </>{" "}
        </Tab>
      ))}
    </div>
  );
}
function Tab({ htmlId, controls, isSelected, onSelected, children }) {
  return (
    <div
      id={htmlId}
      role="tab"
      // aria-seleted={isSelected}
      aria-controls={controls}
      onClick={onSelected}
      className={`retab__tablist__tab ${isSelected ? "selected" : ""}`}
    >
      {children}
    </div>
  );
}
