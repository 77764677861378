import React from "react";
import "./iqDistribution.css";
import iqDistributionWidgetImage from "./iqDistributionWidgetImage.svg";
import getReady from "./getReady.svg";
import score from "./score.svg";
import certificate from "./certificate.svg";
const IqDistribution = () => {
  return (
    <div className="IqDistribution">
      <div className="IqDistributionHeading">
        IQ Distribution in general population
      </div>
      <div className="IqDistributionWidgetContainer">
        <div className="IqDistributionStats">
          <ul>
            <li>
              <span className="numberColor">Above 144 =</span> Highly Gifted
            </li>
            <li>
              <span className="numberColor">130 - 144 =</span> Gifted
            </li>
            <li>
              <span className="numberColor">115 - 129 =</span> Above Average
            </li>
            <li>
              <span className="numberColor">85 - 114 =</span> Average
            </li>
            <li>
              <span className="numberColor">70 - 84 =</span> Below Average
            </li>
            <li>
              <span className="numberColor">Below 70 = </span>Lower Extreme
            </li>
          </ul>
        </div>
        <div className="iqDistributionStatsImageParent">
          <img
            className="iqDistributionStatsImage"
            src={iqDistributionWidgetImage}
            alt="iqDistributionStatsImage"
          />
        </div>
        <div className="startTestWidget">
          <ul>
            <li>
              <div>
                <img height="28" width="28" src={getReady} />
              </div>
              <div className="widgetTextFlex"> Get Ready</div>
            </li>
            <li>
              <div>
                <img height="28" width="28" src={getReady} />
              </div>
              <div className="widgetTextFlex">Complete The Test</div>
            </li>
            <li>
              <div>
                <img height="28" width="28" src={score} />
              </div>
              <div className="widgetTextFlex">Get your IQ Score</div>
            </li>
            <li>
              <div>
                <img height="28" width="28" src={certificate} />
              </div>
              <div className="widgetTextFlex">
                IQ Certificate <br />
                <span className="normalText">
                  and an extensive Performance Report on your intellectual
                  strengths and weaknesses!
                </span>
              </div>
            </li>
            <li className="flexCenter">
              <div className="testButton">Start the Test Now </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IqDistribution;
