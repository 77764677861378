import React from "react";
import Footer from "../footer/footer";
import './testCompleted.css';
import orderPageGirl from './orderPageGirl.svg'
import arrowTest from '../../components/arrowTest.svg';
import OrderDetail from "./orderDetail";
import iqScore from './iqScore.svg';
import certificate from './certificate.svg';
import performanceReport from './performanceReport.svg';
import enhancementTip from './enhancementTip.svg';
const TestCompleted = () => {

  return (
    <div>
        <div className="TestCompletedDiv">
            <div className="TopDiv">
                <div className="TopLeftDiv">
                    <div className="timeForTestComp SmallBoldTxt">
                        Superb! Your performance was unbelievable. You completed the test in: 
                    </div>
                    <div className="TopLeftDivWrapper">
                        <div className="BigBoldTxt">
                            Well Done!
                            <br />
                            Now go get your results and discover how high your IQ is!
                        </div>
                        <div className="TopLeftAnotherDivContent SmallTxt">
                            <div >Please follow the step below to instantly get the following:</div>
                            <div className="InnerContent"><img width="5%" src={arrowTest} alt="arrow" />&emsp;IQ Score</div>
                            <div className="InnerContent"><img width="5%"  src={arrowTest} alt="arrow" />&emsp;IQ Report</div>
                            <div className="InnerContent"><img  width="5%" src={arrowTest} alt="arrow" />&emsp;Performance Report</div>
                        </div>
                    </div>
                </div>
                <div className="orderPageGirlImg"><img src={orderPageGirl} className="orderPageGirl" alt="orderPageGirl image" /></div>
            </div>
            <div>
            <div className=" OrderHeading BigBoldTxt">Your Order Details</div>
            <div className="OrderDetails">
                <OrderDetail img={iqScore} mainHeading={"Your IQ Score"} rightSideHeading={"IQ Score Evaluation"} content={"Your Final IQ Score"}/>
                <OrderDetail img={certificate} mainHeading={"IQ Certificate"} rightSideHeading={"Prinatble IC IQ Certificate"} content={"Your personal IQ Score"} downloadLinkContent={"(Download high quality PDF with IC watermark )"}/>
                <OrderDetail img={performanceReport} mainHeading={"Performance Report"} rightSideHeading={"IC Performance Report"} content={"A 17 page assesment report and analysis of your cognitive performance"} downloadLinkContent={"(Downloadable high quality pdf) "}/>
                <OrderDetail img={enhancementTip} mainHeading={"IQ Enhancement Tips"} rightSideHeading={"Weekly IQ Enhancement Tips "} content={"We curate latest research in cognitive science that will help you take your IQ to the next level."} downloadLinkContent={"(weekly mailer delivered to your inbox)"}/>
                <div className="Total BigBoldTxt">Total:</div>
                <button className='resultBtn BigBoldTxt'> Get My IQ Results Now</button>
            </div>
        </div>
        </div>
      <Footer />
    </div>
  );
};

export default TestCompleted;
