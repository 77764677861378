import React from "react";
import Footer from "../footer/footer";
import "./paymentCompleted.css";
import { Link } from "react-router-dom";
import startTestBackground from "./startTestBackground.svg";
class PaymentCompleted extends React.Component {
  render() {
    return (
      <>
        <div className="mainContainer">
          <div className="StartTestContainer">
            <div className="StartTestContent">
              <div className="StartTestHeading">
                Payment Completed - Please enter your details to get the results
              </div>
              <div className="paymentSubHeading">
                Please enter your details to generate your personal certificate
                and report
              </div>
              <Link to="/test">
                <div className="startTestButton">Generate Results Now</div>
              </Link>
            </div>
            <div className="StartTestImageContainer">
              <img className="StartTestImage" src={startTestBackground} />
            </div>
          </div>
          {/* <img src={wave} alt="wave" /> */}
        </div>
        <Footer />
      </>
    );
  }
}
export default PaymentCompleted;
