import React from "react";
import ProcessWidget from "./processWidget";
import twentyquestions from "./twentyquestions.svg";
import "./process.css";
import fillDetails from "./fillDetails.svg";
import performanceReport from "./performanceReport.svg";
const Process = () => {
  return (
    <div className="processContainer">
      <ProcessWidget
        className="widget"
        image={twentyquestions}
        heading="20 Questions"
        subHeading="Created By the IC<sup>TM</sup> IQ Research team"
      />
      <ProcessWidget
        className="widget"
        image={fillDetails}
        heading="Fill Your Details"
        subHeading="To Send Your Report"
      />
      <ProcessWidget
        className="widget"
        image={performanceReport}
        heading="Performance Report"
        subHeading="Get your IQ Certificate and Performance Report"
      />
    </div>
  );
};

export default Process;
