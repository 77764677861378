import React, { useEffect, useState } from "react";
import { Line } from "rc-progress";
import "./timer.css";
function Timer(props) {
  const [counter, setCounter] = useState(600);
  const [minutes, setMinutes] = useState(9);
  const [seconds, setSeconds] = useState(59);
  const [percentage, setpercentage] = useState(100);
  useEffect(() => {
    const time =
      counter > 0 &&
      setInterval(() => {
        setSeconds(seconds - 1);

        if (seconds == 0) {
          if (minutes > 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
        setpercentage(counter / 6);
        setCounter(counter - 1);
      }, 1000);
    return () => clearInterval(time);
  }, [counter]);
  return (
    <>
      <div>
        <div className="time-heading">
          <div>Time Left</div> <div className="space-between"></div> 0{minutes}:
          {seconds < 10 ? "0" : ""}
          {seconds}
        </div>
      </div>
      <Line
        percent={percentage}
        strokeWidth="4"
        trailWidth="4"
        strokeColor="#0091FF"
      />
    </>
  );
}

export default Timer;
