import React, { useState, useEffect } from "react";
import Timer from "../../components/timer";
import QuestionPanel from "./components/questionPanel";
import Question from "./components/question";
import AnswerPanel from "./components/answerPanel";
import questions from "./questionsJson.js";
import "./panel.css";
import "./test.css";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
window.answerKey = {};
export default function Test(props) {
  const [selected, setSelected] = useState(props.selected || 0);
  const [rerenderpage, changeState] = useState("");
  let tabs = [],
    panels = [],
    options = [];
  useEffect(() => {
    document.getElementById("mainDiv") &&
      (document.getElementById("mainDiv").style.background = "#e5e5e5");

    return () => {
      document.getElementById("mainDiv") &&
        (document.getElementById("mainDiv").style.background = "#fff");
    };
  }, []);
  const onAnswerSelection = (option) => {
    tabs[selected].answerSelected = option;
    changeState(option);
  };
  for (const tabItemDefinition of questions) {
    const uid = tabItemDefinition.id;
    const tabHtmlId = `retab__tabslist__tab-${uid}`;
    const panelHtmlId = `retab__tabpanel-${uid}`;
    const optionHtmlId = `retab__optionpanel-${uid}`;
    tabs.push({
      uid: uid,
      htmlId: tabHtmlId,
      panelHtmlId: panelHtmlId,
      content: tabItemDefinition.id,
      answerSelected: window.answerKey.hasOwnProperty(uid)
        ? window.answerKey[uid]
        : "",
      optionHtmlId: optionHtmlId,
    });

    panels.push({
      uid: uid,
      htmlId: panelHtmlId,
      tabHtmlId: tabHtmlId,
      content: tabItemDefinition.question,
      optionHtmlId: optionHtmlId,
    });
    options.push({
      uid: uid,
      htmlId: optionHtmlId,
      tabHtmlId: tabHtmlId,
      panelHtmlId: panelHtmlId,
      content: tabItemDefinition.options,
    });
  }
  return (
    <div className="testOuterDiv">
      <div className="testMainDiv">
        <div className="chooseDiv">
          <span className="chooseDivText">
            Choose the appropriate shape (A, B, C, D, E or F) to replace the
            shape that is missing.
          </span>
        </div>
        <div className="questionBar">
          <div className="question">
            <span className="questionNumber">{panels[selected].uid}</span>of
            {panels.length}
          </div>
          <div class="timerDiv">
            <Timer />
          </div>

          <div className="prevNext">
            <span
              className="previous"
              onClick={() => {
                selected > 0 && setSelected(selected - 1);
              }}
            >
              Previous
            </span>
            &emsp;
            <span
              className="next"
              onClick={() => selected < 20 && setSelected(selected + 1)}
            >
              Next
            </span>
          </div>
        </div>
        <div className="retab">
          {selected <= tabs.length && (
            <Question
              key={panels[selected].uid}
              controlledBy={panels[selected].tabHtmlId}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={require(`${panels[selected].content}`)}
                alt="Querstion"
              />
            </Question>
          )}
          <QuestionPanel
            ariaLabel={"Vertical Tabs"}
            selected={selected}
            onTabSelected={setSelected}
            definition={tabs}
          />
        </div>
        <div className="selectAnswer">Select Answer</div>
        {selected <= tabs.length && (
          <AnswerPanel
            options={options[selected]}
            key={options[selected].uid}
            onAnswerSelection={onAnswerSelection}
            answerSelected={tabs[selected].answerSelected}
          />
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            marginTop: "5%",
          }}
        >
          <Link to="/testCompleted">
            {" "}
            <div
              onClick={() => {
                fetch(" https://api.ipify.org/?format=json")
                  .then((response) => response.json())
                  .then((data) => {
                    console.log("This is your data", data);
                    fetch(`https://freegeoip.app/json/${data.ip}`)
                      .then((response) => response.json())

                      // response = response.json();
                      //   console.log(response.body.json());
                      // }
                      .then((data) => {
                        console.log(data);
                        console.log(data);
                      });
                  });
              }}
              className="myIqReportButton"
            >
              Get My IQ Report
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}
