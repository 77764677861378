import React from "react";
import Footer from "../footer/footer";
import StartTestComponent from "../../components/startTestComponent";

const StartTest = () => {
  const list = [
    "You have 20 minutes to answer the following 20 questions.",
    "Choose the appropriate shape (A, B, C, D, E or F) to replace the shape that is missing (the one with the question mark on it).",
    "You can skip questions and return back to them later.",
  ];
  return (
    <div>
      <StartTestComponent heading="The IC Certified IQ Test" list={list} />
      <Footer />
    </div>
  );
};

export default StartTest;
