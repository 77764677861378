import React from 'react';
import './icCertifiedTests.css';
import Tests from './tests';
import iqTest from './svg/iqTest.svg';
import ptTest from './svg/ptTest.svg'
import dotsBackground from './svg/dotsBackground.svg'
const IcCertifiedTests = () =>{
    return  <div>
                <div class="testMainHeading">The IC Certified Tests</div>
                <div className="TestsWrapperDiv">          
                    <img className="dotBackground1" src={dotsBackground}/>
                    <Tests imgsrc={iqTest} testHeading="Intelligence Quotient Test (IQ)" description="<div>Determine your IQ score to estimate your intelligence.</div><div>Take the ICI certified intelligence test.</div>" btnTxt="Check Your IQ Now"/>
                    <Tests imgsrc={ptTest} testHeading="Personality Type Test (PT)" description="<div>Find out more about you and your strengths.</div><div>Take the ICI certified personality test.</div>" btnTxt="Know Your Personlity Now"/>
                    <img className="dotBackground2" src={dotsBackground}/>
                </div>
            </div>
}
export default IcCertifiedTests;