/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "./MetronicSplashScreen";
import { Home } from "./app/modules/home/home";
import StartTest from "./app/modules/startTest/startTest";
import StartDemoTest from "./app/modules/startDemoTest/startDemoTest";
import Test from "./app/modules/test/test";
import TestCompleted from "./app/modules/testCompleted/testCompleted";
import PaymentCompleted from "./app/modules/paymentCompleted/paymentCompleted";

export function Routes() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <div id="mainDiv" style={{ paddingTop: "60px" }}>
          <Route exact path="/" component={Home} />
          <Route path="/startTest" component={StartTest} />
          <Route path="/startDemoTest" component={StartDemoTest} />
          <Route path="/test" component={Test} />
          <Route path="/testCompleted" component={TestCompleted} />
          <Route path="/paymentCompleted" component={PaymentCompleted} />
        </div>
      </Switch>
    </Suspense>
  );
}
