import React, { useState } from "react";

const AnswerPanel = ({ options, onAnswerSelection, answerSelected }) => {
  console.log(options.content);
  const [optionSelected, markOptionSelect] = useState(answerSelected);
  let answerDiv = [];
  let makeAnswerDiv = (key, element) => {
    return (
      <div
        className={`optionContainer ${
          optionSelected == key ? "keySelected" : ""
        }`}
      >
        {key}
        <div
          onClick={() => {
            let questionId = options.uid;
            // let answerSelect = {};
            // answerSelect[questionId] = key;
            window.answerKey[questionId] = key;
            // window.answerKey.push(answerSelect);
            console.log(window.answerKey);
            markOptionSelect(key);
            onAnswerSelection(key);
          }}
          className={
            optionSelected == key ? "optionImageSelected" : "optionImage"
          }
        >
          {" "}
          <img src={require(`${element}`)} />{" "}
        </div>
      </div>
    );
  };
  for (const key in options.content) {
    answerDiv.push(makeAnswerDiv(key, options.content[key]));
  }
  return (
    <div className="optionPanel">
      {/* {options.content.map((option, index) => {
        return <img src={require(`${option.content}`)} />;
      })} */}
      {answerDiv}
    </div>
  );
};

export default AnswerPanel;
