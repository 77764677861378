import React from "react";
import "./startTestComonent.css";
import startTestBackground from "./startTestBackground.svg";
import arrowTest from "./arrowTest.svg";
import { Link } from "react-router-dom";
import wave from "./wave.svg";
const StartTestComponent = (props) => {
  console.log(props);
  let showList = (list) => {
    let contentList = list.map((element) => {
      return (
        <li className="startTestListContent">
          <div>
            <img src={arrowTest} alt="arrow" />
          </div>{" "}
          <div className="testRightContent">{element}</div>
        </li>
      );
    });
    return contentList;
  };
  return (
    <div className="mainContainer">
      <div className="StartTestContainer">
        <div className="StartTestContent">
          <div className="StartTestHeading">{props.heading}</div>
          <ul className="StartTestList">{showList(props.list)}</ul>
          <div className="goodLuck">Good Luck!</div>
          <Link to="/test">
            <div className="startTestButton">Start Your Test Now</div>
          </Link>
        </div>
        <div className="StartTestImageContainer">
          <img className="StartTestImage" src={startTestBackground} />
        </div>
      </div>
      {/* <img src={wave} alt="wave" /> */}
    </div>
  );
};
export default StartTestComponent;
