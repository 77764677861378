import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "./../../../logo.svg";
import arrow from "./arrow.svg";
const Footer = () => {
  return (
    <div className="footerWrapper">
      <div className="footerInnerWrapper">
        <div className="footerCol">
          <ul>
            <li>
              <img src={logo} alt="intellect-council" />
            </li>
            <li className="footerCol1">
              Intellect Council is highly experienced test publisher that
              specializes in online testing. The test can be used for many
              reasons. It allows determining one's level of being able to learn,
              to understand, to form concepts, to process information, to apply
              logic and reason, in comparison to the population.
            </li>
            <li className="footerColHeading">
              <div>
                <div>SUBSCRIBE TO OUR NEWSLETTER</div>
                <div>
                  <input className="subscribeInput" placeholder="Enter Name" />
                </div>
                <div>
                  <input className="subscribeInput" placeholder="Enter Email" />
                </div>
                <div className="subscribeCta">
                  Subscribe <img src={arrow} />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="footerCol">
          <div className="footerColHeading">TESTS</div>
          <ul>
            <li>
              <Link>IQ Test</Link>
            </li>
            <li>
              <Link>Personality Test</Link>
            </li>
            <li>
              <Link>Aptitude Test</Link>
            </li>
            <li>
              <Link>Phychometric Test</Link>
            </li>
            <li>
              <Link>Quantitative Analysis Test</Link>
            </li>
          </ul>
        </div>
        <div className="footerCol">
          <div className="footerColHeading">RESOURCES</div>
          <ul>
            <li>
              <Link>What is IQ Test?</Link>
            </li>
            <li>
              <Link>How IQ is assessed?</Link>
            </li>
            <li>
              <Link>What is Personality Test?</Link>
            </li>
            <li>
              <Link>What are different types of personalities?</Link>
            </li>
            <li>
              <Link>How to hire better non-tech resources easily?</Link>
            </li>
            <li>
              <Link>IQ distribution of general public</Link>
            </li>
            <li>
              <Link>Behaviour Analysis & it's impact</Link>
            </li>
          </ul>
        </div>
        <div className="footerCol">
          <div className="footerColHeading">COUNCILS</div>
          <ul>
            <li>
              <Link>About Us</Link>
            </li>
            <li>
              <Link>Contact Us</Link>
            </li>
            <li>
              <Link>Testimorials</Link>
            </li>
            <li>
              <Link>Help</Link>
            </li>
            <li>
              <Link>Terms & Conditions</Link>
            </li>
            <li>
              <Link>Privacy Policy</Link>
            </li>
            <li>
              <Link>Refund Policy</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        Copyright © 2020 Intellect Council Initiative. All Rights Reserved.
      </div>
    </div>
  );
};
export default Footer;
