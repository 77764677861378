const questions = [
  {
    id: "Q1",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q2",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q3",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q4",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q5",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q6",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q7",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q8",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q9",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q10",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q11",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q12",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q13",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q14",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q15",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q16",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q17",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q18",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
  {
    id: "Q19",
    question: "./question1.PNG",
    options: {
      A: "./A.png",
      B: "./A.png",
      C: "./A.png",
      D: "./A.png",
      E: "./A.png",
      F: "./A.png",
    },
  },
];
export default questions;
