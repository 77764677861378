import React from "react";
import "./profile.css";
import profileImage from "./profileImage.svg";
import asterisk from "./asterisk.svg";
const Profile = () => {
  return (
    <div className="profileContainer">
      <div className="profileImage">
        <img src={profileImage} alt="profile Image" />
      </div>
      <div className="profileTestContent">
        <div className="flexContainer">
          <div className="boldContent">
            The most professional online IQ test I have ever taken!
          </div>

          <div className="asteriskContainer">
            <div>
              <img src={asterisk} alt="asterisk" />
            </div>
            <div className="asteriskContent">
              The most professional online IQ test I have ever taken! Especially
              the report was excellent. Provides a detailed breakdown of your
              performance on various cognitive areas, it even shows you how you
              compare with others!
            </div>
          </div>
          <div className="NameContent">John Lynn</div>
        </div>
        <div className="extraDiv">
          <div className="boldContentNumber">8,547+</div>
          <div className="TestCompleted">Test Completed</div>
          <div className="StartTestNow">Start Test Now</div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
