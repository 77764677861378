import React from "react";
import { Link } from "react-router-dom";
import "./sideDrawer.css";
const sideDrawer = (props) => (
  <nav className="sideDrawer">
    <ul className="sideNavContainer" style={{ background: "#fff" }}>
      {props.navLinks.map((link) => (
        <li className="sideNavContainerItems" key={link.text}>
          <Link
            to={link.path}
            onClick={() => {
              props.click();
            }}
            style={{ color: "#444" }}
          >
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

export default sideDrawer;
