import React from "react";
import "./processWidget.css";
const ProcessWidget = (props) => {
  console.log(props);
  return (
    <div
      className="widget"
      style={{
        backgroundImage: `url(${props.image})`,
        backgroundSize: "cover",
      }}
    >
      <div className="headingWidget">
        {props.heading}
        <div
          className="subHeading"
          dangerouslySetInnerHTML={{ __html: props.subHeading }}
        ></div>
      </div>
    </div>
  );
};

export default ProcessWidget;
