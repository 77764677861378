import React from "react";
import girlBoy from "./girlBoy.svg";
import stats from "./stats.svg";
import "./home.css";
import IqDistribution from "./components/iqDistribution/iqDistribution";
import IcCertifiedTests from "./components/icCertifiedTests/icCertifiedTests";
import Process from "./components/process/process";
import Profile from "./components/profile/profile";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
export function Home() {
  return (
    <div>
      <div className="TopSection">
        <div className="TopContent">
          <div className="TopCOntentDiv">
            <p className="heading">World's most accurate</p>
            <p className="heading">online IQ Test</p>
            <div>
              <img src={stats} alt="stats" />
              <span className="cognitive">Test Your cognitive skills!</span>
            </div>
            <Link to="/startTest">
              {" "}
              <button className="IqButton">Find your IQ score now </button>
            </Link>
            <div className="intellectCouncil">
              INTELLECT COUNCIL INITIATIVE
              <div className="iqResearch">
                IQ Research - Personality and Intelligence Assesement Tool
              </div>
            </div>
          </div>
        </div>
        <div class="girlBoyImage">
          <img src={girlBoy} className="girlBoy" alt="girlBoy image" />
        </div>
      </div>
      <IqDistribution />
      <Process />
      <IcCertifiedTests />
      <Profile />
      <Footer />
    </div>
  );
}
