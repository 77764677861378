import React from "react";
const CONTENT_DEFINITION = [
  {
    uid: Math.floor(100000 + Math.random() * 900000),
    tab: "Q1",
    panel: "You can use plain text",
  },
  {
    uid: Math.floor(100000 + Math.random() * 900000),
    tab: "Q2",
    panel: <p>Or any element</p>,
  },
  {
    uid: Math.floor(100000 + Math.random() * 900000),
    tab: "Q3",
    panel: (
      <>
        <p>Even multiple elements!</p>
        <p>but of course you already knew React.Fragment</p>
      </>
    ),
  },
  {
    uid: Math.floor(100000 + Math.random() * 900000),
    tab: "Q4",
    panel: (
      <>
        <p className="tabpanel-title">And if you decide to get creative</p>
        <small className="tabpanel-subtitle">With some CSS</small>
        <p className="tabpanel-text">
          You can style everything to your liking!
        </p>
      </>
    ),
  },
];
export default CONTENT_DEFINITION;
