import React from "react";
import Footer from "../footer/footer";
import StartTestComponent from "../../components/startTestComponent";

const StartDemoTest = () => {
  const list = [
    "It takes about 3 minutes to complete and it will give you a good approximation of your real IQ Score.",
  ];
  return (
    <div>
      <StartTestComponent heading="Welcome to Demo Test" list={list} />
      <Footer />
    </div>
  );
};

export default StartDemoTest;
