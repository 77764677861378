import React, { useState } from "react";
import "./ResponsiveNavigation.css";
import { Link } from "react-router-dom";
import hamburger from "./hamburger.svg";
export function ResponsiveNavigation({
  navLinks,
  logo,
  drawerToggleClickHandler,
}) {
  console.log(navLinks);

  return (
    <>
      <nav className="responsive-toolbar" style={{ background: "#fff" }}>
        <img
          className={"hamburger"}
          src={hamburger}
          alt="hamburger menu"
          onClick={() => {
            console.log("hiii");
            drawerToggleClickHandler();
          }}
        />
        <Link to="/homepage">
          <img className="logo" src={logo} alt="intellect-council" />
        </Link>
        <ul style={{ background: "#fff" }}>
          {navLinks.map((link) => (
            <li className="nav-items" key={link.text}>
              <Link to={link.path} style={{ color: "#444" }}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
