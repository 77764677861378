import React from 'react';

const OrderDetail = (props) =>{
    return  <div className="OrderDetailDiv">
                <div className="OrderLeftSideDiv">
                    <div><img src={props.img} /></div>
                    <div className="OrderDetailHeading BigBoldTxt">{props.mainHeading}</div>
                </div>
                <div className="OrderMiddleDiv"></div>
                <div className="OrderDetailRightSideDiv">
                    <div className="OrderDetailHeading BigBoldTxt">{props.rightSideHeading}</div>
                    <div className="SmallTxt">{props.content}</div>
                    {props.downloadLinkContent ? <div className="DownloadPdfLinkContent SmallTxt">{props.downloadLinkContent}</div> : ""}
                </div>
            </div>
}
export default OrderDetail;