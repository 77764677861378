import React from "react";

export default function Question({ htmlId, controlledBy, children }) {
  return (
    <div
      id={htmlId}
      role="tabpanel"
      aria-labelledby={controlledBy}
      className="retab__tabpanel"
    >
      {children}
    </div>
  );
}
